// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-survey-slider-js": () => import("./../../../src/pages/survey-slider.js" /* webpackChunkName: "component---src-pages-survey-slider-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-use-cases-use-case-111-jsx": () => import("./../../../src/pages/UseCases/UseCase111.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-111-jsx" */),
  "component---src-pages-use-cases-use-case-112-jsx": () => import("./../../../src/pages/UseCases/UseCase112.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-112-jsx" */),
  "component---src-pages-use-cases-use-case-113-jsx": () => import("./../../../src/pages/UseCases/UseCase113.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-113-jsx" */),
  "component---src-pages-use-cases-use-case-121-jsx": () => import("./../../../src/pages/UseCases/UseCase121.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-121-jsx" */),
  "component---src-pages-use-cases-use-case-122-jsx": () => import("./../../../src/pages/UseCases/UseCase122.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-122-jsx" */),
  "component---src-pages-use-cases-use-case-123-jsx": () => import("./../../../src/pages/UseCases/UseCase123.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-123-jsx" */),
  "component---src-pages-use-cases-use-case-21-jsx": () => import("./../../../src/pages/UseCases/UseCase21.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-21-jsx" */),
  "component---src-pages-use-cases-use-case-22-jsx": () => import("./../../../src/pages/UseCases/UseCase22.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-22-jsx" */),
  "component---src-pages-use-cases-use-case-23-jsx": () => import("./../../../src/pages/UseCases/UseCase23.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-23-jsx" */),
  "component---src-pages-use-cases-use-case-24-jsx": () => import("./../../../src/pages/UseCases/UseCase24.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-24-jsx" */),
  "component---src-pages-use-cases-use-case-31-jsx": () => import("./../../../src/pages/UseCases/UseCase31.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-31-jsx" */),
  "component---src-pages-use-cases-use-case-32-jsx": () => import("./../../../src/pages/UseCases/UseCase32.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-32-jsx" */),
  "component---src-pages-use-cases-use-case-33-jsx": () => import("./../../../src/pages/UseCases/UseCase33.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-33-jsx" */),
  "component---src-pages-use-cases-use-case-34-jsx": () => import("./../../../src/pages/UseCases/UseCase34.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-34-jsx" */),
  "component---src-pages-use-cases-use-case-41-jsx": () => import("./../../../src/pages/UseCases/UseCase41.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-41-jsx" */),
  "component---src-pages-use-cases-use-case-42-jsx": () => import("./../../../src/pages/UseCases/UseCase42.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-42-jsx" */),
  "component---src-pages-use-cases-use-case-43-jsx": () => import("./../../../src/pages/UseCases/UseCase43.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-43-jsx" */),
  "component---src-pages-use-cases-use-case-44-jsx": () => import("./../../../src/pages/UseCases/UseCase44.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-44-jsx" */),
  "component---src-pages-use-cases-use-case-51-jsx": () => import("./../../../src/pages/UseCases/UseCase51.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-51-jsx" */),
  "component---src-pages-use-cases-use-case-52-jsx": () => import("./../../../src/pages/UseCases/UseCase52.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-52-jsx" */),
  "component---src-pages-use-cases-use-case-53-jsx": () => import("./../../../src/pages/UseCases/UseCase53.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-53-jsx" */),
  "component---src-pages-use-cases-use-case-54-jsx": () => import("./../../../src/pages/UseCases/UseCase54.jsx" /* webpackChunkName: "component---src-pages-use-cases-use-case-54-jsx" */),
  "component---src-pages-user-info-js": () => import("./../../../src/pages/user-info.js" /* webpackChunkName: "component---src-pages-user-info-js" */)
}

