/* eslint-disable no-console */
// See: https://www.gatsbyjs.org/docs/browser-apis/

import scrollToElement from 'scroll-to-element'
import { get, isFunction } from 'lodash'

export const onRouteUpdate = ({ location, prevLocation }) => {
  let { hash } = location
  if (hash) {
    scrollToElement(hash, {
      offset: -96,
      duration: 1000,
    })
  }

  // Only want to force a page view for analytics after the initial page view.
  // By checking for the existence of prevLocation, we can skip this function
  // call when there is no prevLocation, which is on initial app load, and every
  // route update after that will have a prevLocation, so we call the analytics
  // page view.
  if (prevLocation) {
    // This function spawned out of this ticket https://app.clickup.com/t/2308664/ACC-3088
    // where we need to force a page view to fire in order to track analytics correctly
    const analyticsChangeContext = get(window, '__analyticsChangeContext', [])
    if (isFunction(analyticsChangeContext.push)) {
      analyticsChangeContext.push({
        eventData: {
          eventName: 'page_view',
        },
      })
    }
  }
  require('@styles/idd/main.scss')
  require('bootstrap/dist/css/bootstrap.min.css')
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.href.indexOf('#') > -1) {
    return false
  } else return true
}

export const onClientEntry = () => {}
